import $ from 'jquery';
import jQuery from 'jquery';
import 'what-input';
import anime from 'animejs/lib/anime.es.js';

import Swup from 'swup';
import SwupPreloadPlugin from '@swup/preload-plugin';
import SwupJsPlugin from '@swup/js-plugin';
import SwupScrollPlugin from '@swup/scroll-plugin';
import SwupFormsPlugin from '@swup/forms-plugin';


// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.$ = $;
window.jQuery = jQuery;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

function activeState() {
  let currentPage = location.pathname;
  $('a').removeClass("active");
  $('a').each(function() {
    var currentHref = $(this).attr('href');
    if(currentHref == currentPage) {
        $(this).addClass("active");
    }
  });
}

document.addEventListener("DOMContentLoaded", function(){
  const overlay = document.querySelector("#overlay");
  const overlay_logo = document.querySelector(".overlay__logo");
  let loading = anime.timeline({
    easing: "easeInOutExpo",
    duration: 3000
  })
  .add({
    targets: overlay_logo,
    scale: [1, 0],
    opacity: [1, 0],
    duration: 1000
  })
  .add({
    targets: overlay,
    opacity: [1, 0],
    translateX: ["0%", "-100%"],
    duration: 2000
  }, "-=1000")

});

function heartbeat() {
  let heart = anime.timeline({
    duration: 500,
    complete: function() {heart.restart();}
  })
  .add({
    targets: ".shortcut__icon .fa-heartbeat",
    keyframes: [
      {scale: .9},
      {scale: 1.1},
    ],
    easing: 'easeInOutSine',
    duration: 500
  })

  let clock = anime.timeline({
    duration: 2000,
    complete: function() {clock.restart();}
  })
  .add({
    targets: ".shortcut__icon .fa-clock",
    keyframes: [
      {rotate: "0deg"},
      {rotate: "180deg"},
      {rotate: "360deg"}
    ],
    easing: "easeInOutSine"
  })
}

function menuOpen(){
  const MOBILE = {};
  MOBILE.box = document.querySelector(".mobile-overlay");
  MOBILE.left = MOBILE.box.querySelector(".m-left");
  MOBILE.right = MOBILE.box.querySelector(".m-right");
  MOBILE.closer = MOBILE.right.querySelector(".menuCloser");
  MOBILE.img = MOBILE.left.querySelector(".m-logo");

  let menuOpener = anime.timeline({
    easing: "easeInQuart"
  })
  .add({
    targets: MOBILE.box,
    translateY: 0,
    duration: 0
  })
  .add({
    targets: MOBILE.left,
    translateY: ["100%", "0%"],
    duration: 750
  })
  .add({
    targets: MOBILE.right,
    translateY: ["-100%", "0%"],
    duration: 750
  }, "-=450")
  .add({
    targets: MOBILE.img,
    translateX: ["-2rem", "0"],
    opacity: [0, 1],
    duration: 300
  })
  .add({
    targets: ".mobile-menu a",
    translateX: ["2rem", "0"],
    opacity: [0, 1],
    delay: anime.stagger(100),
    duration: 600
  }, "-=300")
}

function menuClose() {
  const MOBILE = {};
  MOBILE.box = document.querySelector(".mobile-overlay");
  MOBILE.left = MOBILE.box.querySelector(".m-left");
  MOBILE.right = MOBILE.box.querySelector(".m-right");
  MOBILE.closer = MOBILE.right.querySelector(".menuCloser");
  MOBILE.img = MOBILE.left.querySelector(".m-logo");

  let menuClosing = anime.timeline({
    easing: "easeOutQuart"
  })
  .add({
    targets: ".mobile-menu a",
    translateX: ["0", "2rem"],
    opacity: [1, 0],
    duration: 300,
    delay: anime.stagger(50)
  })
  .add({
    targets: MOBILE.img,
    translateX: ["0", "-2rem"],
    opacity: [1, 0],
    duration: 300
  }, "-=300")
  .add({
    targets: MOBILE.right,
    translateY: ["0%", "-100%"],
    duration: 500
  })
  .add({
    targets: MOBILE.left,
    translateY: ["0%", "100%"],
    duration: 500
  }, "-=500")
  .add({
    targets: MOBILE.box,
    translateY: "-100%",
  })
}

function theBeat(){	
  const BEAT = {};	
  BEAT.svg = document.querySelector("#the_beat");	
  BEAT.curve1 = BEAT.svg.querySelector("#curve-1 path");	
  BEAT.curve2 = BEAT.svg.querySelector("#curve-2 path");	
  BEAT.curve3 = BEAT.svg.querySelector("#curve-3 path");	
  BEAT.curve4 = BEAT.svg.querySelector("#curve-4 path");	
  BEAT.curve5 = BEAT.svg.querySelector("#curve-5 path");	
  BEAT.right = BEAT.svg.querySelector(".m-right");	
  BEAT.closer = BEAT.svg.querySelector(".menuCloser");	
  BEAT.img = BEAT.svg.querySelector(".m-logo");	

 anime({	
   targets: '#the_beat circle, #the_beat ellipse, #the_beat path',	
   strokeDashoffset: [anime.setDashoffset, 0],	
   easing: 'easeInOutSine',	
   duration: 300,	
   delay: anime.stagger(100, {start: 1000})	
 });	

 let letters = anime.timeline({	
   targets: "header h1 span"	
 })	
 .add({	
  color: ["#B10F0F", "#3A3A3A"],	
  easing: 'easeInExpo',	
  duration: 1100,	
  delay: anime.stagger(50),	
 })	
}

function menuScroll(){
  const infobar = $('.infobar').outerHeight();
  const topbar = $('.topbar');
  $(window).on('scroll', function(){
    if ($(document).scrollTop() > infobar) {
      topbar.addClass('fixed');
    } else {
      topbar.removeClass('fixed');
    }
  });
}

function menuToggle(){
  
}

function init(){

  if (document.querySelector('#the_beat')) {
    theBeat();
  }

  if (localStorage.getItem('doctor-q')!='1') {
    $('#js-doctor-q').show();
  } else {
    $('#js-doctor-q').hide();
  }

  if (localStorage.getItem('cookie_text')!='1') {
    $('#js-cookie-text').show();
  } else {
    $('#js-cookie-text').hide();
  }

  menuScroll();

  $("#slider").on("before-slide-change.fndtn.orbit", function(event) {
    $('.orbit-caption').fadeOut(100);
  });
  $("#slider").on("after-slide-change.fndtn.orbit", function(event, orbit) {
      $('.orbit-caption').fadeIn(1500);
  });

  $('.hamburger').on('click', function(){
    console.log('Öffne...');
    menuOpen();
  });

  $('.menuCloser').on("click", function(){
    menuClose();
  });

  $('#js-doctor').on("click", function(){
    let doctorq = anime.timeline({
      easing: "easeOutExpo"
    })
    .add({
      targets: ".doctor__overlay",
      scaleX: 0,
      duration: 350
    })
  })

  $(document).foundation();
}

const options = [
  {
    from: '(.*)',
    to: '(.*)',
    in: (next) => {
      document.querySelector('.loader').style.height = "50%";
      anime.timeline({
        complete: () => {
          next();
        }
      }).add({
        targets: ".loader",
        height: ["50%", "0%"],
        zIndex: "-1",
        duration: 1000,
        easing: "easeInOutSine",
        delay: 500
      })
    },
    out: (next) => {
      document.querySelector('.loader').style.height = "0%";
      anime.timeline({
        complete: () => {
          next();
        }
      }).add({
        targets: ".loader",
        zIndex: "105",
        height: ["0%", "50%"],
        duration: 500,
        easing: "easeInOutSine"
      })
    }
  }
]

const swup = new Swup({
  plugins: [
    new SwupJsPlugin(options),
    new SwupScrollPlugin({
      doScrollingRightAway: true
    }),
    new SwupPreloadPlugin(),
    new SwupFormsPlugin()
  ]
});

swup.on('contentReplaced', init);

init();
activeState();

document.addEventListener('swup:contentReplaced', event => {
  activeState();
});

